<template>
    <div class="Customer">
        <el-form ref="formRef" status-icon :model="form" :rules="rules" label-width="100px">
            <el-row>
                <el-col :span="11">
                    <el-form-item label="客户类型" prop="type">
                        <el-select :ref="el=>refMap.set('type',el)" v-model="form.type" clearable placeholder="请选择客户类型" style="width: 100%" :disabled="disabled" @change="(val)=>{selectOnChange(val,'type')}">
                            <el-option v-for="item in comboSelect({comboId:'type'})" :key="item.value" :label="item.label" :value="item.value"/>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="11" :offset="2">
                    <el-form-item label="客户名称" prop="name">
                        <el-input @input="e => form.name = validForbid(e)" v-model="form.name" placeholder="请输入客户名称" maxlength="25" :disabled="disabled" clearable/>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="11">
                    <el-form-item label="所属行业" prop="industry">
                        <el-select :ref="el=>refMap.set('industry',el)" v-model="form.industry" clearable placeholder="请选择所属行业" style="width: 100%" :disabled="disabled" @change="(val)=>{selectOnChange(val,'industry')}">
                            <el-option v-for="item in comboSelect({comboId:'industry'})" :key="item.value" :label="item.label" :value="item.value"/>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="11" :offset="2">
                    <el-form-item label="客户状态" prop="status">
                        <el-select :ref="el=>refMap.set('status',el)" v-model="form.status" clearable placeholder="请选择客户状态" style="width: 100%" :disabled="disabled" @change="(val)=>{selectOnChange(val,'status')}">
                            <el-option v-for="item in comboSelect({comboId:'status'})" :key="item.value" :label="item.label" :value="item.value"/>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="11">
                    <el-form-item label="联系人" prop="contacts">
                        <el-input @input="e => form.contacts = validForbid(e)" v-model="form.contacts" placeholder="请输入联系人" maxlength="25" :disabled="disabled" clearable/>
                    </el-form-item>
                </el-col>
                <el-col :span="11" :offset="2">
                    <el-form-item label="联系电话" prop="telephone">
                        <el-input @input="e => form.telephone = validForbid(e)" v-model="form.telephone" placeholder="请输入联系电话" maxlength="13" :disabled="disabled" clearable/>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="11">
                    <el-form-item label="所在省" prop="provinceId">
                        <el-select :ref="el=>refMap.set('provinceId',el)" v-model="form.provinceId" clearable placeholder="请选择所在省" style="width: 100%" :disabled="disabled" @change="(val)=>{selectOnChange(val,'provinceId')}">
                            <el-option v-for="item in provinceData" :key="item.value" :label="item.label" :value="item.value"/>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="11" :offset="2">
                    <el-form-item label="所在市" prop="cityId">
                        <el-select :ref="el=>refMap.set('cityId',el)" v-model="form.cityId" clearable placeholder="请选择所在市" style="width: 100%" :disabled="disabled" @change="(val)=>{selectOnChange(val,'cityId')}">
                            <el-option v-for="item in cityData" :key="item.value" :label="item.label" :value="item.value"/>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="24">
                    <el-form-item label="联系地址" prop="address">
                        <el-input @input="e => form.address = validForbid(e)" v-model="form.address" placeholder="请输入联系地址" maxlength="122" :disabled="disabled" clearable/>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="24">
                    <el-form-item label="备注" prop="remark">
                        <el-input @input="e => form.remark = validForbid(e)" type="textarea" v-model="form.remark" placeholder="请输入备注" maxlength="122" :disabled="disabled" clearable/>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row v-show="!addStatus">
                <el-col :span="12">
                    <el-form-item label="是否停用">
                        <span>{{form.flag==0?'启用':'禁用'}}</span>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="创建时间">
                        <span>{{form.time}}</span>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <EditTable :ref="el=>refMap.set('customerAddress',el)" v-bind="cardDetailParam('customerAddress')">
            <template v-slot:tbCols>
                <el-table-column label="联系人" prop="F_CONTACTS"  >
                    <template #default="scope">
                        <span v-if="!scope.row.EDIT" style="margin-left: 10px">{{scope.row.F_CONTACTS}}</span>
                        <el-input v-else placeholder="请输入联系人" @input="e => scope.row.F_CONTACTS = validForbid(e)" v-model="scope.row.F_CONTACTS" maxlength="25" clearable/>
                    </template>
                </el-table-column>
                <el-table-column label="联系电话" prop="F_TELEPHONE"  >
                    <template #default="scope">
                        <span v-if="!scope.row.EDIT" style="margin-left: 10px">{{scope.row.F_TELEPHONE}}</span>
                        <el-input v-else placeholder="请输入联系电话" v-model="scope.row.F_TELEPHONE" maxlength="13" clearable/>
                    </template>
                </el-table-column>
                <el-table-column label="联系地址" prop="F_ADDRESS"  >
                    <template #default="scope">
                        <span style="margin-left: 10px">{{scope.row.F_ADDRESS}}</span>
                    </template>
                </el-table-column>

                <el-table-column label="地址所属省" prop="F_PROVINCE_NAME">
                    <template #default="scope">
                        <span v-if="!scope.row.EDIT">{{ scope.row.F_PROVINCE_NAME }}</span>
                        <el-select v-else :ref="el=>refMap.set('F_PROVINCE_ID',el)" v-model="scope.row.F_PROVINCE_ID" placeholder="请选择" @change="(val)=>{selectOnChange(val,'detailProvinceId')}">
                            <el-option v-for="item in provinceData" :key="item.value" :label="item.label" :value="item.value"/>
                        </el-select>
                    </template>
                </el-table-column>
                <el-table-column label="地址所属市" prop="F_CITY_NAME">
                    <template #default="scope">
                        <span v-if="!scope.row.EDIT">{{ scope.row.F_CITY_NAME }}</span>
                        <el-select v-else :ref="el=>refMap.set('F_CITY_ID',el)" v-model="scope.row.F_CITY_ID" placeholder="请选择" @change="(val)=>{selectOnChange(val,'detailCityId')}">
                            <el-option v-for="item in detailCityData" :key="item.value" :label="item.label" :value="item.value"/>
                        </el-select>
                    </template>
                </el-table-column>
                <el-table-column label="地址所属区" prop="F_AREA_ID">
                    <template #default="scope">
                        <span v-if="!scope.row.EDIT">{{ scope.row.F_AREA_NAME }}</span>
                        <el-select v-else :ref="el=>refMap.set('F_AREA_ID',el)" v-model="scope.row.F_AREA_ID" clearable placeholder="请选择"  @change="(val)=>{selectOnChange(val,'detailAreaId')}">
                            <el-option v-for="item in areaData" :key="item.value" :label="item.label" :value="item.value"/>
                        </el-select>
                    </template>
                </el-table-column>
                <el-table-column label="地址类别" prop="F_TYPE">
                    <template #default="scope">
                        <span v-if="!scope.row.EDIT">{{ scope.row.F_TYPE_NAME }}</span>
                        <el-select v-else :ref="el=>refMap.set('F_TYPE',el)" v-model="scope.row.F_TYPE" clearable placeholder="请选择">
                            <el-option v-for="item in comboSelect({comboId:'F_TYPE'})" :key="item.value" :label="item.label" :value="item.value"/>
                        </el-select>
                    </template>
                </el-table-column>
                <el-table-column label="操作">
                    <template #default="scope">
                        <el-button size="mini" @click="chooseAddress(scope.$index, scope.row)">选择地址</el-button>
                    </template>
                </el-table-column>
            </template>
        </EditTable>
    </div>
</template>


<script>
    import CustomerCardHelper from "./CustomerCardHelper.js";
    export default CustomerCardHelper;
</script>

<style scoped>
    .Customer{
        width: 100%;
    }
</style>
