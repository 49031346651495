import {ref, reactive, toRefs, computed,watch,defineComponent, onMounted, getCurrentInstance,provide} from 'vue';
const CustomerCardHelper = defineComponent({
    name:'CustomerCard',
    title:'客户',
    modelType:'card',
    fullscreen: true,
    setup(){
        let {proxy}=getCurrentInstance();
        const utils=proxy.utils;
        provide('EditTableOwner', proxy);
        let dataObj=reactive({
            formRef:null,
            disabled:false,
            refMap:new Map(),
            provinceData:[],
            cityData:[],
            detailCityData:[],
            areaData:[],
            //卡片传给dialog的初始化参数
            compParams: {
                hsDetails: true,
                details:['customerAddress'],
                modelPath: "/customer"
            },
            //表单
            form:{},
            //表单验证规则
            rules: {
                type: [
                    {required: true, message: "请选择客户类型", trigger: "blur" }
                ],
                name: [
                    {required: true, message: "请输入客户名称", trigger: "blur" }
                ],
                industry: [
                    {required: true, message: "请选择所属行业", trigger: "blur" }
                ],
                status: [
                    {required: true, message: "请选择客户状态", trigger: "blur" }
                ],
                contacts: [
                    {required: true, message: "请输入联系人", trigger: "blur" }
                ],
                provinceId: [
                    {required: true, message: "请选择省", trigger: "blur" }
                ],
                telephone: [
                    { required: true,validator: utils.$$str.checkPhone, trigger: 'blur' }
                ],
                address: [
                    {required: true, message: "请输入联系地址", trigger: "blur" }
                ],
            }
        })

        onMounted(()=>{
        })
        //---------------------------computed---------------------------
        //下拉select数据集获取（数据集构造和cascader类似）
        const comboSelect=computed(()=>{
            return (params) => {
                if(params && 'type'==params.comboId){
                    return [{value:0,label:'个人'},{value:1,label:'机关单位'},{value:2,label:'国企'},{value:3,label:'民企'},{value:4,label:'外资'},{value:5,label:'合资'}]
                }
                if(params && 'F_TYPE'==params.comboId){
                    return [{value:0,label:'卸货点'},{value:1,label:'装货点'}]
                }
                if(params && 'industry'==params.comboId){
                    return [{value:0,label:'零售'},{value:1,label:'电商'},{value:2,label:'物流'},{value:3,label:'工业'},{value:4,label:'其它'}]
                }
                if(params && 'status'==params.comboId){
                    return [{value:0,label:'公海'},{value:1,label:'待签'},{value:2,label:'已签'},{value:3,label:'已解约'}]
                }
                if(params && 'provinceId'==params.comboId){
                    return dataObj.provinceData;
                }
                if(params && 'cityId'==params.comboId){
                    return dataObj.cityData;
                }
                if(params && 'detailProvinceId'==params.comboId){
                    return dataObj.provinceData;
                }
                if(params && 'detailCityId'==params.comboId){
                    return dataObj.detailCityData;
                }
                if(params && 'detailAreaId'==params.comboId){
                    return dataObj.areaData;
                }
            }
        })
        const buildProvinceData=async ()=>{
            dataObj.provinceData= await utils.$$api.getProvinces({});//表头省
        }
        const buildCityData=async (provinceId)=>{
            dataObj.cityData = await utils.$$api.getCitiesByProvince({provinceId:provinceId});//表头城市
        }
        const buildDetailCityData=async (provinceId)=>{
            dataObj.detailCityData = await utils.$$api.getCitiesByProvince({provinceId:provinceId});//明细城市
        }
        const buildAreaDataByCity=async (cityId)=>{
            dataObj.areaData = await utils.$$api.getAreasByCity({cityId:cityId});//明细按城市加载区域
        }
        const buildAreaDataByProvince=async (provinceId)=>{
            dataObj.areaData = await utils.$$api.getAreasByProvince({provinceId:provinceId});//明细按省加载区域
        }
        //---------------------------selectOnchange--------------------------
        const selectOnChange=async(newValue,selectId)=>{
            if('type'==selectId){}
            if('industry'==selectId){}
            if('status'==selectId){}
            if('provinceId'==selectId){
                dataObj.form.cityId='';
                await buildCityData(newValue);
                // await buildAreaDataByProvince(newValue);
                // await dataObj.refMap.get('customerAddress').clearDetailData();
            }
            if('cityId'==selectId){
                // dataObj.areaData=[];
                // await buildAreaDataByCity(newValue);
                // await dataObj.refMap.get('customerAddress').clearDetailData();
            }
            if('detailProvinceId'==selectId){
                dataObj.detailCityData=[];
                dataObj.areaData=[];
                await buildDetailCityData(newValue);
                await buildAreaDataByProvince(newValue);
            }
            if('detailCityId'==selectId){
                dataObj.areaData=[];
                await buildAreaDataByCity(newValue);
            }
        }
        //---------------------------computed---------------------------
        //明细表格初始化参数
        const cardDetailParam=computed(()=>{
            return (detailType) => {
                return {
                    detailParam: {
                        title:'客户地址维护',
                        readOnly:dataObj.disabled,
                        canPage: false,
                        queryParam: {
                            detailType: detailType
                        },
                        modelMethod: "/customer/detail?t="+Math.random()*1000
                    }
                }
            }
        })
        //打开弹出框的回调事件(已请求完/add或/edit，data是返回值)
        const beforeOpen=async(data,addOrLoad,cardEngine)=>{
            if('/add'==addOrLoad){
                dataObj.addStatus=true;
            }else {
                await buildCityData(data.data.provinceId);
            }
            await buildProvinceData();
        }
        //行开始编辑事件回调方法，点击某一行编辑或新增一行都会调用该方法。区别：新增行的时候不会传入options,options为undifine，编辑的时候options为{row: row, column: column}
        const beganEdit=async(detailType,options)=>{
            // if(dataObj.form.cityId){
            //     await buildAreaDataByCity(dataObj.form.cityId);
            // }else if(dataObj.form.provinceId){
            //     await buildAreaDataByProvince(dataObj.form.provinceId);
            // }
            if(!options)return true;
            if(options.row.F_CITY_ID){
                await buildAreaDataByCity(options.row.F_CITY_ID);
            }
            if(options.row.F_PROVINCE_ID){
                if(!options.row.F_CITY_ID)await buildAreaDataByProvince(options.row.F_PROVINCE_ID);
                await buildDetailCityData(options.row.F_PROVINCE_ID);
            }
            return true;
        }
        //结束编辑行的时候，为该行指定列设置显示文本，方便不是编辑状态的情况下显示
        const beforeEndEdit=(detailType, row, index)=>{
            if("customerAddress"==detailType){
                let flag=utils.$$str.phoneValidate(row['F_TELEPHONE']);
                if(!flag){
                    proxy.$message('电话号码不合法');
                    return false;
                }
                if(!row['F_PROVINCE_ID']){
                    proxy.$message('请选择地址所属省');
                    return false;
                }
                if(!row['F_AREA_ID']){
                    proxy.$message('请选择地址所属区');
                    return false;
                }
                row.F_AREA_NAME =dataObj.refMap.get('F_AREA_ID').selected.currentLabel;//下拉select的显示文本
                row.F_CITY_NAME =dataObj.refMap.get('F_CITY_ID').selected.currentLabel;//下拉select的显示文本
                row.F_PROVINCE_NAME =dataObj.refMap.get('F_PROVINCE_ID').selected.currentLabel;//下拉select的显示文本
                row.F_TYPE_NAME =dataObj.refMap.get('F_TYPE').selected.currentLabel;//下拉select的显示文本
                dataObj.refMap.get('customerAddress').setRowData(row, index);//重新设置该行的值
            }
            return true;
        }
        //保存之前，校验整个保存数据是否合法
        const beforeSaveHandler=()=>{
            //验证明细表格数据是否合法
            let customerAddressData=dataObj.refMap.get('customerAddress').getDetailData();

            for(let i=0;i<customerAddressData.length;i++){
                let row=customerAddressData[i];
                let f1=utils.$$str.isEmpty(row['F_CONTACTS']);
                let f2=utils.$$str.isEmpty(row['F_TELEPHONE']);
                let f3=utils.$$str.isEmpty(row['F_ADDRESS']);
                let f4=utils.$$str.isEmpty(row['F_COORDINATE']);
                let f5=utils.$$str.isEmpty(row['F_PROVINCE_ID']);
                let f6=utils.$$str.isEmpty(row['F_AREA_ID']);
                if(f1 || f2 || f3 || f5 || f6){
                    utils.$$tools.warning({message:'第'+(i+1)+"行信息不完整"});
                    return false;
                }
                if(f4){
                    utils.$$tools.warning({message:'第'+(i+1)+"没有地址坐标，请重新选择地址"});
                    return false;
                }
            }
            return true;
        }
        //明细表格加载完毕事件
        //const gridLoaded=(detailType,res)=>{
        //    res.rows.forEach((row)=> {
        //        row.F_D_FIELD9 = parseInt(row.F_D_FIELD9);
        //    });
        //}
        const startHandler=async()=>{
            utils.$$lghdUtils.startHandler({id:dataObj.form.id,proxy:proxy,url:dataObj.compParams.modelPath + "/custom"});
        }
        const stopHandler=async()=>{
            utils.$$lghdUtils.stopHandler({id:dataObj.form.id,proxy:proxy,url:dataObj.compParams.modelPath + "/custom"});
        }
        const chooseAddress=async (index,row)=>{
            await utils.$$lghdUtils.buildConfigAddressDialog({from:'customerCard',proxy:proxy,index:index,id:row['F_ID']});
            // await utils.$$lghdUtils.buildChooseAddressDialog({proxy:proxy,customerId:dataObj.form.id});
        }
        //地图弹出框回调事件，在这可以拿到弹出框的实例，里面有选择的地址坐标等信息
        const sureHandler=async (modelInst)=>{
            if(modelInst.coordinate==0){
                proxy.$message('请点击地图上的地址');
                return false;
            }
            let index=modelInst.index;
            let row=dataObj.refMap.get('customerAddress').getDetailData()[index];
            row['F_ADDRESS']=modelInst.searchContent;
            row['F_COORDINATE']=modelInst.coordinate;
            dataObj.refMap.get('customerAddress').setRowData(row,index);
            return true;
        }
        return{
            ...toRefs(dataObj),cardDetailParam,beganEdit,beforeEndEdit
            ,comboSelect,selectOnChange,beforeOpen,beforeSaveHandler,startHandler,stopHandler,chooseAddress,sureHandler
         //,gridLoaded
        }
    }
});
export default CustomerCardHelper;